var puzzle = {
    game: {
        settings: {},
        init: function(settings) {
            settings.holder = $(settings.holder);
            this.settings = settings;
            this.settings.config = $(settings.holder).data('config');
        },
        start: function(level) {
            if(this.gameStarted) {return;}
            var _this = this;

            var rows = 0;
            switch(level) {
                case 1:
                    rows = 2;
                break;
                case 2:
                    rows = 3;
                break;
                case 3:
                    rows = 4;
                break;
            }

            this.settings.rows = rows;
            this.settings.puzzleItemWidth = $(this.settings.holder).width() / this.settings.rows;

            this.gameStarted = true;
            this.showHintFlag = false;
            this.current_level_is_being_played = level;
            this.current_level = level;//todo
            this.drawPuzzle(level);
            $(puzzle.game.settings.holder).find('span.initial_image').fadeOut();
            this.settings.holder.find('span.cover').fadeOut('fast', function() {
                setTimeout(function() {
                    $('[data-puzzle-buttons]:not(.hide)', _this.gameContainer).fadeOut('fast', function() {
                        if(window.isMobile) {
                            $('[data-puzzle-game-hint]', _this.gameContainer).removeClass('inactive');
                            $('[data-puzzle-game-reset]', _this.gameContainer).css('display', 'block');
                        } else {
                            $('[data-puzzle-game-hint]', _this.gameContainer).css('display', 'inline-block');
                            $('[data-puzzle-game-reset]', _this.gameContainer).css('display', 'inline-block');
                        }
                        $('[data-puzzle-game-start]', _this.gameContainer).hide();
                        $(this, _this.gameContainer).fadeIn('fast');
                        _this.shufflePuzzle();
                    });
                }, 200)
            })
        },
        restart: function(level) {
            if(!this.gameStarted || this.showHintFlag) {return;}
            this.gameStarted = true;
            this.showHintFlag = false;
            var gameHolder = this.settings.holder;
            
            $(gameHolder).find('.' + this.settings.holderItemClassName).last()
            .addClass('empty')
            .attr('data-current-position', this.settings.rows * this.settings.rows)
            .data('currentPosition', this.settings.rows * this.settings.rows);

            this.shufflePuzzle();
        },
        drawPuzzle: function(level) {
            var gameHolder = this.settings.holder;
            var puzzleItemWidth = this.settings.puzzleItemWidth;
            var rows = this.settings.rows;
            var puzzleItem;
            var initialArrBuffer = [];
            var _this = this;
            
            var imgName = '';

            switch(level) {
                case 1:
                    imgName = $(this.settings.holder).data('config').level_1;
                break;
                case 2:
                    imgName = $(this.settings.holder).data('config').level_2;
                break;
                case 3:
                    imgName = $(this.settings.holder).data('config').level_3;
                break;
            }

            for(i = 1; i <= rows * rows; i++) {
                puzzleItem = $(`<div data-id='`+i+`' class='`+this.settings.holderItemClassName+`'></div>`).appendTo(gameHolder);

                puzzleItem.css({
                    left: (this.system.getRowsCols(i, rows).col * puzzleItemWidth) + 'px',
                    top: (this.system.getRowsCols(i, rows).row * puzzleItemWidth) + 'px',
                    width: this.settings.puzzleItemWidth,
                    height: this.settings.puzzleItemWidth,
                    backgroundSize: $(this.settings.holder).width(),
                    backgroundPosition: '-' + (this.system.getRowsCols(i, rows).col * puzzleItemWidth) + 'px ' + '-' + (this.system.getRowsCols(i, rows).row * puzzleItemWidth) + 'px',
                    backgroundImage: 'url(' + $(this.settings.holder).data('config').img_path + imgName + ')'
                });
                puzzleItem.click(function() {
                    _this.checkForEmptySurrounding(this);
                });

                if(i == rows * rows) {
                    puzzleItem.attr('data-current-position', i);
                    break;
                }
                initialArrBuffer.push(i);
            }
            $(gameHolder).find('.' + this.settings.holderItemClassName).last().addClass('empty');

            this.initialArr = initialArrBuffer;
            this.first_arr = initialArrBuffer.slice(0);
            this.first_arr.push(this.settings.rows * this.settings.rows);
        },
        shufflePuzzle: function() {
            if(this.current_level_is_being_played == 1) {
                var possibleMoves = [
                    [1, 2, 4, 3],
                    [1, 4, 3, 2],
                    [4, 3, 2, 1]
                ];
                this.initialArr = possibleMoves[puzzle.game.system.randomIntFromInterval(0, possibleMoves.length - 1)];
            } else {
                this.system.shuffle(this.initialArr);
            }
            this.draw(this.initialArr);
        },
        showHint: function() {
            if(this.showHintFlag || !this.gameStarted) {return false;}

            this.showHintFlag = true;
            var _this = this;
            var gameHolder = this.settings.holder;
            this.currentProgress = [];

            for(var i = 1; i <= this.settings.rows * this.settings.rows; i++) {
                _this.currentProgress.push(parseInt($(gameHolder).find('[data-current-position="'+i+'"]').attr('data-id')));
            }

            this.draw(this.first_arr);

            setTimeout(function() {
                _this.draw(_this.currentProgress);
                _this.showHintFlag = false;
            }, this.settings.hintTimeSeconds * 1000);
        },
        imitateWinner: function() {
            var buffer = [];
            for(i = 1; i <= this.settings.rows * this.settings.rows; i++) {
                buffer.push(i);
            }
            this.draw(buffer);
            this.checkIfReady();
        },
        draw: function(arr) {
            var gameHolder = this.settings.holder;
            var puzzleItemWidth = this.settings.puzzleItemWidth;
            var rows = this.settings.rows;

            var puzzleItem;

            for(i = 0, count = 1; i < arr.length; i++, count++) {
                if(arr[i] == 'empty') {
                    continue;
                }
                
                puzzleItem = $(gameHolder).find('.' + this.settings.holderItemClassName + '[data-id="'+arr[i]+'"]');

                puzzleItem.css({
                    left: (this.system.getRowsCols(count, rows).col * puzzleItemWidth) + 'px',
                    top: (this.system.getRowsCols(count, rows).row * puzzleItemWidth) + 'px'
                }).attr('data-current-position', count).data('currentPosition', count);
            }
        },
        checkForEmptySurrounding: function(current) {
            if(this.showHintFlag || !this.gameStarted) {return false;}
            var currentEmptyPos = this.system.getCurrentEmptyPos();
            var puzzlePosition = parseInt($(current).data('currentPosition'));

            if(
                (
                    this.system.getRowsCols(currentEmptyPos, this.settings.rows).row 
                    == 
                    this.system.getRowsCols(puzzlePosition, this.settings.rows).row
                    &&
                    (
                        (puzzlePosition + 1) == currentEmptyPos ||
                        (puzzlePosition - 1) == currentEmptyPos
                    )
                )
                ||
                (puzzlePosition + this.settings.rows) == currentEmptyPos ||
                (puzzlePosition - this.settings.rows) == currentEmptyPos
            ) {
                this.swapPuzzle(current, currentEmptyPos, puzzlePosition);
            }
        },
        swapPuzzle: function(current, emptyPosition, puzzlePosition) {
            var current = $(current);
            var empty = this.settings.holder.find('.' + this.settings.holderItemClassName + '.empty');

            current.css({
                left: (this.system.getRowsCols(emptyPosition, this.settings.rows).col * this.settings.puzzleItemWidth) + 'px',
                top: (this.system.getRowsCols(emptyPosition, this.settings.rows).row * this.settings.puzzleItemWidth) + 'px'
            }).data('currentPosition', emptyPosition).attr('data-current-position', emptyPosition);
            empty.css({
                left: (this.system.getRowsCols(puzzlePosition, this.settings.rows).col * this.settings.puzzleItemWidth) + 'px',
                top: (this.system.getRowsCols(puzzlePosition, this.settings.rows).row * this.settings.puzzleItemWidth) + 'px'
            }).data('currentPosition', puzzlePosition).attr('data-current-position', puzzlePosition);

            this.checkIfReady();
        },
        checkIfReady: function() {
            var gameHolder = this.settings.holder;
            var items = $(gameHolder).find('.' + this.settings.holderItemClassName);
            var _this = this;
            var ready = 0;
            items.each(function(k, v) {
                if(!$(v).hasClass('empty')) {
                    if($(v).attr('data-id') != $(v).attr('data-current-position')) {
                        return false;
                    } else {
                        ready++;
                    }
                }
            });
            if(ready == (this.settings.rows * this.settings.rows) - 1) {
                setTimeout(function() {
                    _this.puzzleReady();
                }, 250);
            }
        },
        puzzleReady: function() {
            this.gameStarted = false;
            this.settings.holder.addClass('ready');
            puzzle.overlays.toggleUserData();
            this.gameFinished = true;
            setTimeout(function() {
                $(puzzle.gameContainer).find('[data-puzzle-level-id="'+puzzle.game.current_level_is_being_played+'"] .passed').addClass('show');
                $(puzzle.gameContainer).find('[data-puzzle-level-id="'+puzzle.game.current_level_is_being_played+'"] .not_passed').removeClass('show').addClass('hide');
            }, 1000);
            setTimeout(function() {
                $(puzzle.game.settings.holder).find('span.initial_image').fadeIn('fast', function() {
                    puzzle.checkForNextLevel();
                });
            }, 5000);
        },
        system: {
            shuffle: function(a) {
                var j, x, i, buffer = a;
                for (i = a.length; i; i--) {
                    j = Math.floor(Math.random() * i);
                    x = buffer[i - 1];
                    buffer[i - 1] = buffer[j];
                    buffer[j] = x;
                }
                return buffer;
            },
            getRowsCols: function (puzzleId, rows) {
                return {
                    row: Math.ceil(puzzleId / rows) - 1,
                    col: ((puzzleId % rows) == 0)?(rows - 1):((puzzleId % rows) - 1)
                }
            },
            getCurrentEmptyPos: function() {
                return $(puzzle.game.settings.holder).find('.' + puzzle.game.settings.holderItemClassName + '.empty').data('currentPosition');
            },
            randomIntFromInterval: function(min, max) {
                return Math.floor(Math.random()*(max-min+1)+min);
            }
        }
    },
    validateFormfields: function() {
        var re = /^[\d\s()+-]+$/;
        var errorsFound = false;
        $('[data-puzzle-game-error]', $(puzzle.gameContainer)).hide();
        $('[data-puzzle-game-name], [data-puzzle-game-tel]').removeClass('error');
        if($('[data-puzzle-game-name]').val().trim().length <= 1) {
            $('[data-puzzle-game-name]').addClass('error').prev().show();
            errorsFound = true;
        }
        if($('[data-puzzle-game-tel]').val().trim().length <= 8) {
            $('[data-puzzle-game-tel]').addClass('error').prev().show();
            errorsFound = true;
            if(!re.test($('[data-puzzle-game-tel]').val())) {
                $('[data-puzzle-game-tel]').addClass('error').prev().show();
                errorsFound = true;
            }
        }
        
        if(!errorsFound) {
            puzzle.overlays.toggleUserData();
            puzzle.game.gameFinished = false;
        }
    },
    checkForNextLevel: function() {
        var nextLevel = puzzle.game.current_level_is_being_played + 1;
        var nextLevelContainer = $('[data-puzzle-level-id="'+ nextLevel +'"]');
        if(!nextLevelContainer.hasClass('available')) {return false;}
        nextLevelContainer.find('.buttons.hide').removeClass('hide');
        nextLevelContainer.find('[data-puzzle-game-hint]', this.gameContainer).hide();
        nextLevelContainer.find('[data-puzzle-game-reset]', this.gameContainer).hide();
        nextLevelContainer.find('[data-puzzle-game-start]', this.gameContainer).css('display', 'inline-block');
        puzzle.game.settings.holder.removeClass('ready');
        $('#steps_slider').slick('slickGoTo', (nextLevel - 1));
        $('.' + puzzle.game.settings.holderItemClassName, $(puzzle.game.settings.holder)).remove();
        puzzle.game.init(puzzle.game.settings);
    },
    slider: function() {
        var _this = this;
        var current_level = puzzle.game.settings.config.current_level;
        $('[data-puzzle-game-steps] li').eq(current_level - 1).addClass('active');
        $(puzzle.game.settings.holder).find('span.cover').css('backgroundImage', 'url(' + puzzle.game.settings.config.img_path + eval('puzzle.game.settings.config.level_'+(current_level)+'_cover') + ')');
        $(puzzle.game.settings.holder).find('span.initial_image').css('backgroundImage', 'url(' + puzzle.game.settings.config.img_path + eval('puzzle.game.settings.config.level_'+(current_level)) + ')');

        var arrowsSize = "fa-4x";
        if(window.isMobile) {
            arrowsSize = "fa-3x";
        }

        $('#steps_slider').slick({
            prevArrow: '<a href="#" class="arrows left"><i class="fa fa-chevron-left ' + arrowsSize + '" aria-hidden="true"></i></a>',
            nextArrow: '<a href="#" class="arrows right"><i class="fa fa-chevron-right ' + arrowsSize + '" aria-hidden="true"></i></a>',
            initialSlide: current_level?(current_level - 1):0,
            speed: 500,
            infinite: false
        }).on('afterChange', function(event, slick, index) {
            $('[data-puzzle-game-steps] li').removeClass('active');
            $('[data-puzzle-game-steps] li').eq(index).addClass('active');
            $(puzzle.game.settings.holder).find('span.cover').css('backgroundImage', 'url(' + puzzle.game.settings.config.img_path + eval('puzzle.game.settings.config.level_' + (index + 1) + '_cover') + ')');
        });
        $('[data-puzzle-game-steps] li').click(function() {
            if(puzzle.game.gameFinished) {return false;}
            $('#steps_slider').slick('slickGoTo', $(this).index());            
            $(puzzle.game.settings.holder).find('span.cover').css('backgroundImage', 'url(' + puzzle.game.settings.config.img_path + eval('puzzle.game.settings.config.level_' + ($(this).index() + 1) + '_cover') + ')');
        });
    },
    overlays: {
        init: function() {
            this.overlay = $('#game_overlay');
            this.game_modal_windows = $('.game_modal_windows');

            this.bindOpen();
            this.bindClose();
        },
        open: function(modal_window_classname) {
            $('.game_modal_windows.' + modal_window_classname, this.gameContainer).fadeIn('fast');
            this.overlay.fadeIn('fast');
        },
        close: function(modal_window_classname) {
            $('.game_modal_windows.' + modal_window_classname, this.gameContainer).fadeOut('fast');
            this.overlay.fadeOut('fast');
        },
        bindOpen: function() {
            var _this = this;
            $('[data-puzzle-game-open]', this.gameContainer).click(function(e) {
                e.preventDefault();
                $('.game_modal_windows.' + $(this).attr('data-puzzle-game-open'), _this.gameContainer).fadeIn('fast');
                _this.overlay.fadeIn('fast');
            });
        },
        bindClose: function() {
            var _this = this;
            $('[data-puzzle-game-close]', this.gameContainer).click(function(e) {
                e.preventDefault();
                $('.game_modal_windows.' + $(this).attr('data-puzzle-game-close'), _this.gameContainer).fadeOut('fast');
                _this.overlay.fadeOut('fast');
            });
            this.overlay.click(function() {
                $('.game_modal_windows', _this.gameContainer).fadeOut('fast');
                _this.overlay.fadeOut('fast');
            })
        },
        toggleUserData: function() {
            $('div.user_data', this.gameContainer).fadeToggle();
        }
    },
    init: function() {
        this.game.init({
            holder: '#game',
            holderItemClassName: 'holderItem',
            hintTimeSeconds: 2
        });
        this.gameContainer = $('#gameContainer');
        this.overlays.init();
        this.slider();
    }
}


$(document).ready(function() {
    puzzle.init();
});









